import { PERMISSIONS } from '../../guards/Permissions';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);
const role = window.localStorage.getItem('role');
const ICONS = {
  user: getIcon('ic_user'),
  surcussale: getIcon('ic_surcussale'),
  transac: getIcon('transac'),
  analytics: getIcon('ic_analytics'),
  borlette: getIcon('ic_borlette'),
  limite: getIcon('ic_limite'),
  tirage: getIcon('ic_tirage'),
  rapport: getIcon('ic_rapport'),
  supervisor: getIcon('ic_supervisor'),
  agent: getIcon('ic_agent')
};

export const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      // MANAGEMENT : BOLETTE
      {
        title: 'tableau de bord',
        path: PATH_DASHBOARD.general?.app,
        icon: ICONS.limite
      },
      // MANAGEMENT : TIRAGE
      {
        title: 'configuration',
        path: PATH_DASHBOARD.tirage?.configuration,
        icon: ICONS.tirage,
        children: [
          { title: 'Tirages', path: PATH_DASHBOARD.tirage?.info_tirage, perm: 'view_infotirage' },
          ...(role === 'superuser'
            ? [{ title: 'Prime Generale', path: PATH_DASHBOARD.tirage?.configuration_list, perm: 'view_tauxtirage' }]
            : [{ title: 'Prime Generale', path: PATH_DASHBOARD.tirage?.configuration, perm: 'view_tauxtirage' }]),
          {
            title: 'Prime Agent',
            path: PATH_DASHBOARD.tirage?.configuration_type('agent'),
            perm: 'view_agenttauxtirage'
          },
          {
            title: 'Prime Tirage',
            path: PATH_DASHBOARD.tirage?.configuration_type('tirage'),
            perm: 'view_tiragetauxtirage'
          },
          {
            title: ' Mariage gratuite',
            path: PATH_DASHBOARD.configuration_borlette?.intervalMa,
            perm: 'view_tiragetauxtirage'
          },
          {
            title: 'superviseur',
            path: PATH_DASHBOARD.supervisor?.root,
            perm: 'view_supervisor'
          },
          {
            title: 'Message',
            path: PATH_DASHBOARD.user?.promote,
            perm: 'view_apppromote'
          },
          {
            title: 'Pays',
            path: PATH_DASHBOARD.user?.country,
            perm: 'view_country'
          }
        ]
      },
      // MANAGEMENT : RAPPORT

      ...(role === 'superuser'
        ? [
            {
              title: 'Compagnie',
              path: PATH_DASHBOARD.company?.root,
              icon: ICONS.surcussale,
              perm: 'view_company',
              children: [
                { title: 'Ajouter', path: PATH_DASHBOARD.company?.newCompany, perm: 'add_company' },
                { title: 'listes', path: PATH_DASHBOARD.company?.list, perm: 'view_company' },
                { title: 'listes options', path: PATH_DASHBOARD.borlette?.lotto, perm: 'view_lotto' },
                { title: 'Compagnie paiment', path: PATH_DASHBOARD.company?.companyPayment, perm: 'view_payment' }
              ]
            }
          ]
        : []),
      {
        title: 'Generale',
        path: PATH_DASHBOARD.generale.root,
        icon: ICONS.surcussale,
        perm: 'view_generale',
        children: [
          { title: 'Ajouter', path: PATH_DASHBOARD.generale?.newGenerale, perm: 'add_generale' },
          { title: 'listes', path: PATH_DASHBOARD.generale?.list, perm: 'view_generale' }
        ]
      },
      {
        title: 'vendeur',
        path: PATH_DASHBOARD.agent?.root,
        icon: ICONS.agent,
        perm: 'view_agent',
        children: [
          { title: 'Ajouter', path: PATH_DASHBOARD.agent?.newAgent, perm: 'add_agent' },
          { title: 'listes', path: PATH_DASHBOARD.agent?.list, perm: 'view_agent' }
        ]
      },
      {
        title: 'surveillance',
        path: PATH_DASHBOARD.borlette?.statistique,
        icon: ICONS.borlette,
        children: [
          { title: 'Lots gagnant', path: PATH_DASHBOARD.tirage?.list, perm: 'view_tirage' },
          { title: 'Lots gagnant Auto', path: PATH_DASHBOARD.tirage?.newTirageAuto, perm: 'add_tirage_auto' },
          // { title: 'fiches vendu', path: PATH_DASHBOARD.borlette?.list },
          { title: 'blocage boule', path: PATH_DASHBOARD.borlette?.blocage_boule, perm: 'view_bouleblock' },
          { title: 'statistiques', path: PATH_DASHBOARD.borlette?.statistique, perm: PERMISSIONS.VIEW_STATISTIQUE },
          { title: 'listes options', path: PATH_DASHBOARD.borlette?.lotto, perm: 'view_lotto' },
          { title: 'Limite Jeu', path: PATH_DASHBOARD.configuration_borlette?.type_game, perm: 'view_typegame' },
          {
            title: 'Limite Jeu Par Agent',
            path: PATH_DASHBOARD.configuration_borlette?.type_game_agent,
            perm: 'view_typegameagent'
          },
          { title: 'Limite Boule', path: PATH_DASHBOARD.configuration_borlette?.type_boule, perm: 'view_typeboule' },
          {
            title: 'Limite Boule Par Agent',
            path: PATH_DASHBOARD.configuration_borlette?.type_boule_agent,
            perm: 'view_typebouleagent'
          },

          {
            title: 'Limite Fiche',
            path: PATH_DASHBOARD.limiteFiche?.list,
            perm: 'view_typebouleagent'
          },

          { title: 'Tracabilites Agents', path: PATH_DASHBOARD.user?.tracabilite_agent, perm: 'view_traceagentaction' },
          {
            title: 'Tracabilites Superviseurs',
            path: PATH_DASHBOARD.user?.tracabilite_supervisor,
            perm: 'view_tracesupervisoraction'
          },
          ...(role === 'superuser'
            ? [
                {
                  title: 'Tracabilites Compagnies',
                  path: PATH_DASHBOARD.user?.tracabilite_company,
                  perm: 'view_tracecompanyaction'
                }
              ]
            : []),
            {
              title: 'Transactions',
              path: PATH_DASHBOARD.transaction.root,
              icon: ICONS.transac,
              perm: 'view_wallettransaction'
            },
            {
              title: 'Facturation',
              path: PATH_DASHBOARD.company.companyFactu,
              icon: ICONS.transac,
              perm: 'view_wallettransaction'
            },

           
      
          // { title: 'Rapports Journalier', path: PATH_DASHBOARD.rapport?.journalier }
        ]
      },

      // MANAGEMENT : SUPERVISOR

      // MANAGEMENT : SUCCURSALE
      {
        title: 'succursale',
        path: PATH_DASHBOARD.surcussale?.root,
        icon: ICONS.surcussale,
        perm: 'view_surcussale',
        children: [
          { title: 'Ajouter', path: PATH_DASHBOARD.surcussale?.newSurcussale, perm: 'add_surcussale' },
          { title: 'listes', path: PATH_DASHBOARD.surcussale?.list, perm: 'view_surcussale' }
        ]
      },

      // MANAGEMENT : AGENT
      {
        title: 'rapports',
        path: PATH_DASHBOARD.rapport?.root,
        icon: ICONS.analytics,
        children: [
          { title: 'Ventes', path: PATH_DASHBOARD.rapport?.root, perm: PERMISSIONS.VIEW_REPORT_VENTE },
          { title: 'Control Agent', path: PATH_DASHBOARD.rapport?.agent, perm: PERMISSIONS.VIEW_REPORT_AGENT },
          { title: 'fiches vendu', path: PATH_DASHBOARD.borlette?.list, perm: 'view_fichevente' },
          { title: 'fiches gagnant', path: PATH_DASHBOARD.borlette?.list_fgagnant, perm: PERMISSIONS.VIEW_FICHE_WIN },
          { title: 'fiches Payant', path: PATH_DASHBOARD.borlette?.list_fpayant, perm: PERMISSIONS.VIEW_FICHE_WIN },
          { title: 'fiches eliminés', path: PATH_DASHBOARD.borlette?.list_fsupp, perm: PERMISSIONS.VIEW_FICHE_DELETE }
          // { title: 'Rapports Journalier', path: PATH_DASHBOARD.rapport?.journalier }
        ]
      },

   
      // MANAGEMENT : COMPANY
      ...(role !== 'superuser'
        ? [
            {
              title: 'mon compte',
              icon: ICONS.user,
              path: PATH_DASHBOARD.user?.root,
              children: [
                { title: 'Editer Profile', path: PATH_DASHBOARD.user?.account },
                {
                  title: 'Tracabilites du compagnie',
                  path: PATH_DASHBOARD.user?.tracabilite_company,
                  perm: 'view_tracecompanyaction'
                },
                { title: 'Historiques Paiment', path: PATH_DASHBOARD.company?.companyPayment, perm: 'view_payment' },
                { title: ' Paiment de Facture', path: PATH_DASHBOARD.company?.companyPayPayment ,perm: 'view_payment' },
                
                { title: 'Generer x agents', path: PATH_DASHBOARD.user?.generateAgent,perm: 'add_agent' }
              ]
            }
          ]
        : [])
    ]
  }
];

export default { sidebarConfig };
