import { useRef, useState, useEffect } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import Scrollbar from '../../components/Scrollbar';
import { useDispatch, useSelector } from '../../redux/store';
import {
  getAppJournalier,
  getCountries,
  getAgentRapports,
  getSupRapports,
  getASRapports,
  getApp,
  getLotGagnants
} from '../../redux/slices/user';
import Request from '../../utils/Request';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const { user, initialize } = useAuth();
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { countries } = useSelector((state) => state.user);
  const [load, setLoad] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  const handleChoose = async (countrycode) => {
    setLoad(true);
    const values = {
      country_code: countrycode
    };
    await Request.handleChooseCountry(values);
    await initialize();
    dispatch(getAppJournalier());
    dispatch(getApp());
    dispatch(getAgentRapports());
    dispatch(getSupRapports());
    dispatch(getASRapports());
    dispatch(getLotGagnants());
    handleClose();
  };

  const currentCountry = countries?.find((e) => e.name_code === user?.select_country_code);

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <img
          src={currentCountry?.flag}
          alt={currentCountry?.name}
          style={{
            width: 30,
            height: 30,
            borderradius: 8
          }}
        />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ py: 1 }}>
        <Scrollbar sx={{ height: 450 }}>
          {countries.map((option) => (
            <MenuItem
              key={option.name_code}
              selected={option.name_code === currentCountry?.name_code}
              onClick={() => handleChoose(option.name_code)}
              sx={{ py: 1, px: 1 }}
            >
              <ListItemIcon>
                <Box
                  component="img"
                  alt={option.name}
                  src={option.flag}
                  sx={{
                    width: 30,
                    height: 30,
                    borderradius: 8
                  }}
                />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{option.name}</ListItemText>
            </MenuItem>
          ))}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
