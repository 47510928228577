import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState, useEffect } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha, useTheme } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import Scrollbar from '../../components/Scrollbar';
import { getCompagnies, getApp, getLotGagnants, getAppJournalier, getSimpleCompagnies } from '../../redux/slices/user';
import { useDispatch, useSelector } from '../../redux/store';
import Request from '../../utils/Request';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: PATH_DASHBOARD.user?.account
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout, initialize } = useAuth();
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const { compagnies } = useSelector((state) => state.user);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      enqueueSnackbar('Impossible de se déconnecter', { variant: 'error' });
    }
  };

  const handleChoose = async (refcode) => {
    setLoad(true);
    const values = {
      ref_code: refcode
    };
    await Request.handleChooseCompany(values);
    await initialize();
    dispatch(getApp());
    dispatch(getAppJournalier());
    dispatch(getLotGagnants());
    // enqueueSnackbar('Choose with success', { variant: 'success' });
    handleClose();
  };

  const displayName = user?.role === 'company' ? user?.companyname : `${user?.first_name} ${user?.last_name}`;
  let roleName = 'Unknow'; // Default value

  if (user?.role === 'company') {
    roleName = 'Admin';
  } else if (user?.role === 'supervisor') {
    roleName = 'Superviseur';
  } else if (user?.role === 'superuser') {
    roleName = 'Super Admin';
  }

  useEffect(() => {
    if (user?.role === 'superuser') {
      dispatch(getSimpleCompagnies());
    }
  }, [user?.role]);

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar compagnies={compagnies} />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {roleName}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />
        {user?.role === 'superuser' && (
          <Box sx={{ p: 2, pt: 1.5 }}>
            <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
              Se déconnecter+
            </Button>
          </Box>
        )}
        {user?.role !== 'superuser' ? (
          MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24
                }}
              />

              {option.label}
            </MenuItem>
          ))
        ) : (
          <Scrollbar sx={{ height: 450 }}>
            {[
              { ref_code: 'all', user_photo: '/static/icons/navbar/ic_surcussale.svg', companyname: 'Toutes' },
              ...compagnies
            ].map((comp) => (
              <MenuItem
                key={comp.ref_code}
                onClick={() => handleChoose(comp.ref_code)}
                sx={{
                  typography: 'body2',
                  py: 1,
                  px: 2.5,
                  ...(comp.ref_code === user.select_company && {
                    bgcolor: theme.palette.primary.main
                  }),
                  '&:hover': {
                    bgcolor: theme.palette.secondary.main,
                    color: 'common.black'
                  }
                }}
              >
                <img
                  src={comp.user_photo}
                  alt="logo"
                  style={{
                    marginRight: 2,
                    width: 30,
                    height: 30
                  }}
                />
                <Typography
                  sx={{
                    ...(comp.ref_code === user.select_company && {
                      color: 'common.white'
                    })
                  }}
                >
                  {comp.companyname}
                </Typography>
              </MenuItem>
            ))}
          </Scrollbar>
        )}
        {user?.role !== 'superuser' && (
          <Box sx={{ p: 2, pt: 1.5 }}>
            <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
              Se déconnecter
            </Button>
          </Box>
        )}
      </MenuPopover>
    </>
  );
}
